import { Directive, HostListener, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

declare const Calendly: any;

@Directive({
	selector: '[pkWebCalendlyWidget]'
})
export class CalendlyWidgetDirective {

	@HostListener('click') onClick() { this.openCalendly() }
	@Input() url = environment.calendlyDemo;

	public openCalendly(): boolean {
		Calendly.initPopupWidget({ url: this.url });
		return false;
	}

}
