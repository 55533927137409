import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppStateService {

	public onSetNavbarClass = new Subject<{ navbarClass: string, isInitial: boolean }>();

	constructor() { }

	public setNavbarClass(navbarClass: string, isInitial = true): void {
		this.onSetNavbarClass.next({ navbarClass, isInitial });
	}
}
