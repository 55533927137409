/* eslint-disable @typescript-eslint/naming-convention */
export const environmentShared = {
	timezone: 'America/Chicago',
	calendlyDemo: 'https://calendly.com/pk-sales/demo',
	calendlyCall: 'https://calendly.com/matt-redden/learn-more',
	apiKeys: { // put alphabetically to make it easy to find
		'http://localhost:4200': 'e6b0adf418e0498f93b0bb5de0e56b2d',
		'https://test.powerkiosk.com': '796c49d2d9fb41749a5d3c4948f5a223',
		'https://powerkiosk.com': '346d094e9cba4c00aa24b198f5aa3b3e',
	}
};
