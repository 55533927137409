import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLinkedin as fabLinkedIn, faSquareFacebook as fabSquareFacebook } from '@fortawesome/free-brands-svg-icons';
import {
	faBars as fasBars,
	faCaretRight as fasCaretRight,
	faChartColumn as fasChartColumn,
	faChartKanban as fasChartKanban,
	faCheck as fasCheck,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasChevronRight,
	faCircle as fasCircle,
	faCircleCheck as fasCircleCheck,
	faCircleDollar as fasCircleDollar,
	faCircleQuestion as fasCircleQuestion,
	faEnvelopeCircleCheck as fasEnvelopeCircleCheck,
	faFileCertificate as fasFileCertificate,
	faFileSignature as fasFileSignature,
	faGears as fasGears,
	faGift as fasGift,
	faGlobePointer as fasGlobePointer,
	faMicrochipAi as fasMicrochipAi,
	faPartyHorn as fasPartyHorn,
	faSeedling as fasSeedling,
	faSpinner as fasSpinner,
	faSquare as fasSquare,
	faTimer as fasTimer,
	faXmark as fasXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { NgbCarouselModule, NgbDropdownModule, NgbModalModule, NgbNavModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { CalendlyWidgetDirective } from './directives/calendly-widget.directive';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
	declarations: [
		CalendlyWidgetDirective,
		LoaderComponent
	],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		FontAwesomeModule,
		NgbDropdownModule,
		NgbOffcanvasModule,
		NgbModalModule,
		NgxMaskDirective,
		NgbCarouselModule,
		NgbNavModule,
	],
	providers: [
		provideNgxMask()
	],
	exports: [
		FontAwesomeModule,
		FormsModule,
		ReactiveFormsModule,
		NgbDropdownModule,
		NgbOffcanvasModule,
		NgbModalModule,
		CalendlyWidgetDirective,
		NgxMaskDirective,
		LoaderComponent,
		NgbCarouselModule,
		NgbNavModule,
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			fabSquareFacebook,
			fabLinkedIn,
			fasBars,
			fasCaretRight,
			fasCircleCheck,
			fasChartColumn,
			fasCheck,
			fasPartyHorn,
			fasGift,
			fasCircle,
			fasChartKanban,
			fasFileCertificate,
			fasGears,
			fasGlobePointer,
			fasTimer,
			fasEnvelopeCircleCheck,
			fasMicrochipAi,
			fasFileSignature,
			fasChevronLeft,
			fasChevronRight,
			fasCircleQuestion,
			fasCircleDollar,
			fasSeedling,
			fasSpinner,
			fasXmark,
			fasSquare
		);
	}
}
